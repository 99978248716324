// Externe bibliotheken
import Alpine from 'alpinejs';
import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import {delegate, followCursor, roundArrow} from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-toward-subtle.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/dist/border.css';

// CSS Imports
import '@splidejs/splide/dist/css/splide-core.min.css';

// Alpine.js plugins
import collapse from '@alpinejs/collapse';
import focus from '@alpinejs/focus';
import intersect from '@alpinejs/intersect';
import mask from '@alpinejs/mask';
import AlpineFloatingUI from "@awcodes/alpine-floating-ui";

// Lokale scripts
import swiper from './swiper.js';
import modal from './modal.js';
import maps from './map.js';
import geolocation from "./geolocation";
import recaptcha from "./recaptcha";
import filamentCompanyPage from "./filament-company-page";
import ToastComponent from '../../vendor/usernotnull/tall-toasts/resources/js/tall-toasts';
import { createStack } from "stackid";
import lottie from "lottie-web/build/player/lottie_light";

// Statische assets (als je dit nodig hebt)
import.meta.glob([
    '../images/**',
]);

// Initialiseren van plugins en configureren van globale objecten
window.stack = createStack();

function initializeAlpinePlugins() {
    Alpine.plugin(mask);
    Alpine.plugin(modal);
    Alpine.plugin(collapse);
    Alpine.plugin(focus);
    Alpine.plugin(intersect);
    // Alpine.plugin(imageModal);
    Alpine.plugin(swiper);
    Alpine.plugin(filamentCompanyPage);
    Alpine.plugin(maps);
    Alpine.plugin(AlpineFloatingUI);
    Alpine.plugin(geolocation);
    Alpine.plugin(recaptcha);
    Alpine.data('ToastComponent', ToastComponent);
}

delegate('body', {
    interactive: true,
    allowHTML: true,
    trigger: 'click',
    theme: 'nieuwbouw',
    animation: 'shift-toward-subtle',
    target: '[data-tippy-content]',
    arrow: true,
    interactiveBorder: 30,
    maxWidth: 320,
    followCursor: false,
    plugins: [followCursor],
});

document.querySelectorAll('.overflow-draggable').forEach(el => {
    const handlePointerEvent = (ev) => {
        if (ev.type === "pointerdown") el.setPointerCapture(ev.pointerId);
        else if (ev.type === "pointerup") el.releasePointerCapture(ev.pointerId);
        else if (ev.type === "pointermove" && el.hasPointerCapture(ev.pointerId)) el.scrollLeft -= ev.movementX;
    };

    el.addEventListener("pointerdown", handlePointerEvent);
    el.addEventListener("pointerup", handlePointerEvent);
    el.addEventListener("pointermove", handlePointerEvent);
})


// Stel Alpine beschikbaar op window
initializeAlpinePlugins();
window.Alpine = Alpine;

Alpine.data('autocomplete', () => ({
    focus: -1,
    selected: false,
    search: '',

    init() {
        this.search = this.$wire.get('search');

        this.$watch('search', () => {
            this.$wire.set('search', this.search)
        })
    },

    setSearch() {
        this.search = this.$wire.get('search');
    },

    setFocus(f) {
        this.focus = f;
    },

    onClick(e) {
        if(!this.$wire.selected && (this.focus >= 0)) {
            e.preventDefault();
            this.$wire.call('select', this.$refs[`item-${this.focus}`]?.dataset.ulid);
            this.focus = -1;
        }
    },

    onBackspace() {
        if(this.$wire.selected) {
            this.search = '';
        }

    },

    onOutside() {
        console.log('onOutside')
        this.$wire.call('close')
    },

    scrollTo(idx) {
        this.$refs[`item-${idx}`]?.scrollIntoView(false);
    },

    focusNext() {
        const nextIndex = this.focus + 1;
        const total = this.$refs.suggestions?.childElementCount ?? 0;

        if (nextIndex < total) {
            this.setFocus(nextIndex);
            this.scrollTo(nextIndex);
        }
    },

    focusPrev() {
        const nextIndex = this.focus - 1;

        if (nextIndex >= 0) {
            this.setFocus(nextIndex);
            this.scrollTo(nextIndex);
        }
    },
}));

Alpine.data('share', () => ({
    shareProject(app) {
        if (typeof app !== 'string' || String(app).trim().length === 0) {
            return;
        }

        if (['facebook', 'whatsapp'].indexOf(app) === -1) {
            console.warn(`Sharing this project via "${app}" is not yet supported.`);
            return;
        }

        let projectUrl = encodeURIComponent(window.location.href);
        let shareUrl;

        if (app === 'facebook') {
            shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${projectUrl}`;
        } else if (app === 'whatsapp') {
            shareUrl = `https://api.whatsapp.com/send?phone=&text=${projectUrl}`;
        }

        // Add campaign for tracking inbound traffic
        shareUrl += encodeURIComponent(`&utm_medium=social&utm_campaign=sharelinks&utm_source=${app}`);

        window.open(shareUrl, '_blank');
    },

    async copyLink() {
        try {
            await navigator.clipboard.writeText(window.location.href);
        } catch (err) {
            console.error(`Failed to copy to clipboard: ${window.location.href}`);
        }
    }
}));

Alpine.data('slideoutBar', () => ({
    isVisible: false,
    thresholdTop: 100,

    init() {
        this.updateVisibility();
        window.addEventListener('scroll', () => {
            this.updateVisibility();
        });
    },

    updateVisibility() {
        const screenWidth = window.innerWidth;
        const scrolledDown = window.scrollY > this.thresholdTop;

        if(screenWidth > 992) {
            this.isVisible = scrolledDown;
        } else {
            const footer = document.querySelector('body > footer');
            const footerTop = footer ? footer.getBoundingClientRect().top : 0;
            const footerInView = footerTop < window.innerHeight;

            this.isVisible = scrolledDown && !footerInView;
        }
    }
}))

Alpine.data('continuousSlider', () => ({
    init() {
        new Splide(this.$el, {
            type        : 'loop',
            autoWidth   : true,
            pagination  : false,
            arrows      : false,
        }).mount( { AutoScroll } );
    }
}))

Alpine.data('loadingScreen', (duration = 10000, max = 100, removeAtEnd = false) => ({
    isVisible: false,
    progress: 0,
    duration: parseInt(duration), // Convert duration to integer
    max: parseInt(max), // Maximum progress value
    removeAtEnd: removeAtEnd, // Maximum progress value

    init() {
        window.addEventListener('start-loading-screen', () => {
            this.startLoading();
        });

        window.addEventListener('stop-loading-screen', () => {
            this.stopLoading();
        });
    },
    startLoading() {
        this.isVisible = true;
        this.progress = 0;
        this.animateProgress();
    },
    stopLoading() {
        this.isVisible = false;
        this.progress = 0;
    },
    animateProgress() {
        let interval = 100; // Update interval in ms
        let increment = (interval / this.duration) * this.max;

        let intervalId = setInterval(() => {
            this.progress += increment;
            if (this.progress >= this.max) {
                clearInterval(intervalId);

                if(this.removeAtEnd) {
                    this.stopLoading();
                }
            }
        }, interval);
    }
}))

Alpine.data('wizardInputButtons', ({ options, fieldName, initialValue  }) => ({
    selectedOption: initialValue || null,

    clickInput(value) {
        this.selectedOption = value;

        this.$wire.set(fieldName, value);
        this.$wire.call('next');
    },

    navigateOptions(direction, event) {
        const currentIndex = options.indexOf(this.selectedOption);
        if (direction === 'next') {
            this.selectedOption = options[(currentIndex + 1) % options.length];
        } else if (direction === 'prev') {
            this.selectedOption = options[(currentIndex - 1 + options.length) % options.length];
        }
        this.$wire.set(fieldName, this.selectedOption);

        event.target.closest('form').querySelector(`[value="${this.selectedOption}"]`).focus();
        event.target.closest('form').querySelector(`[value="${this.selectedOption}"]`).checked = true;
    },

    submitSelection() {
        this.$wire.set(fieldName, this.selectedOption);
        this.$wire.call('next');
    }
}));

Alpine.data('lottieWeb', ({ filename, autoplay = false, loop = false }) => ({
    instance: null,
    isLoaded: false,

    filename,
    autoplay,
    loop,

    init() {
        this.loadLottieJson()
            .then(data => {
                this.loadLottieAnimation(data);
            });
    },
    async loadLottieJson() {
        try {
            const res = await fetch(`/animations/${this.filename}`);
            return await res.json();
        } catch (error) {
            console.error('Failed to load animation JSON:', error);
        }
    },
    loadLottieAnimation(animationData) {
        this.instance = lottie.loadAnimation({
            name: 'mortgage',
            container: this.$refs.lottieContainer,
            renderer: 'svg',
            loop: this.loop,
            autoplay: this.autoplay,
            animationData: animationData
        });

        this.isLoaded = true;
    },
    playAnimation() {
        if (this.isLoaded) {
            setTimeout(() => {
                lottie.play(this.instance.name)
            }, 300)
        } else {
            setTimeout(this.playAnimation.bind(this), 100); // Retry after a short delay
        }
    }
}));

Alpine.data('searchDropdown', ($wire) => ({
    showDropdown: $wire.entangle('showDropdown'),
    showModal: $wire.entangle('showModal'),
    focused: -1,

    init() {
        // alleen bij dropdown, modal is niet nodig om radius te hiden
        this.$watch('showDropdown', (value) => {
            if (value) {
                window.dispatchEvent(new CustomEvent('focus-search'));
            } else {
                window.dispatchEvent(new CustomEvent('blur-search'));
            }
        });
    },

    focusNext() {
        const totalItems = this.getItems().length;
        if (totalItems === 0) return;

        this.focused = (this.focused + 1) % totalItems;
        this.updateFocus();
    },

    focusPrev() {
        const totalItems = this.getItems().length;
        if (totalItems === 0) return;

        this.focused = (this.focused - 1 + totalItems) % totalItems;
        this.updateFocus();
    },

    setFocus(index) {
        this.focused = index;
        this.updateFocus();
    },

    getItems() {
        let items = [];
        if(this.showDropdown) {
            items = this.$refs.suggestionListDropdown.querySelectorAll('li')
        } else if (this.showModal) {
            // zit in modal dus kom er niet bij
            items = this.$el.querySelectorAll('ul[x-ref="suggestionListModal"] li')
        }
        return items;
    },

    updateFocus() {
        const items = this.getItems();
        items.forEach((item, index) => {
            if (index === this.focused) {
                item.focus();
                item.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        });
    },

}))

Alpine.data('editableLabel', ($wire, params = []) => ({
    editable: false,
    params,
    originalValue: '',
    editableLabel: null,

    init() {
        this.editableLabel = this.$refs.editableLabel;

        this.$watch('editable', value => {
            this.editableLabel.setAttribute("contenteditable", value);

            if (value) {
                this.originalValue = this.editableLabel.innerText;
                this.editableLabel.focus();
                this.setSelection();
            }
        });
    },

    setSelection(){
        let sel = window.getSelection();
        sel.selectAllChildren(this.editableLabel);
        sel.collapseToEnd();
    },

    handleClickOutside(event) {
        if (this.editable) {
            this.revertChanges();
        }
    },

    saveChanges() {
        const newValue = this.editableLabel.innerText.trim();
        if (newValue && newValue !== this.originalValue) {
            $wire.call('updateLabel', {"label": newValue, ...this.params});
        } else {
            this.revertChanges();
        }
        this.editable = false;
    },

    revertChanges() {
        this.editableLabel.innerText = this.originalValue;
        this.editable = false;
    }
}));




Alpine.start()
